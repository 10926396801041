import {
  Person,
  Badge,
  PendingActions,
  CheckCircle,
  Grid3x3,
  Phone,
  PlayCircleFilledWhite,
  Visibility,
  Info,
  Email,
} from "@mui/icons-material";
import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  TextField,
  Switch,
  Button,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  Radio,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DatePicker from "app/components/Shared/DatePicker/DatePicker.component";
import { instance } from "app/utils/axiosRequest";
import {
  agentAssignTicket,
  agentAssignTicketDepartment,
  agentGetTemplate,
  agentGetallQueue,
  agentGetallUser,
  agentlistenRecording,
  agentlistenVoicemail,
  updateTicketStatus,
} from "app/utils/api-urls/AgentApiUrls";
import dayjs from "dayjs";
import ReactAudioPlayer from "react-audio-player";
import {
  adminAssignTicket,
  adminAssignTicketDepartment,
  adminlistenRecording,
  adminlistenVoicemail,
  adminupdateTicketStatus,
  allQueueList,
  extensionList,
  getEmailTemplate,
} from "app/utils/api-urls/AdminAPiUrls";
import {
  supervisorAssignTicket,
  supervisorAssignTicketDepartment,
  supervisorGetallQueue,
  supervisorGetallUser,
  supervisorgetEmailTemplate,
  supervisorlistenRecording,
  supervisorlistenVoicemail,
  supervisorupdateTicketStatus,
} from "app/utils/api-urls/SupervisorAPiUrls";
import ChatPreviewModal from "app/pages/dashboard/ChatViewModal";
import { useQuery } from "@tanstack/react-query";
import NotificationModal from "./createNew/NotificationModal";
const ChatPickTicketModal = ({
  openModal,
  handleClosePickTicketPage,
  followData,
  refetch,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 850,
    maxHeight: 600,
    overflowY: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 6,
    borderRadius: 2,
  };

  const userRole = localStorage.getItem("role");

  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const [formValue, setFormValue] = useState({
    remarks: "",
    status: "pending",
    sendNotification: false,
  });

  const [isChatModalOpen, setChatModalOpen] = useState(false);
  const [currentChat, setCurrentChat] = useState(null);

  const [sendNotification, setSendNotification] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [notificationsMessageList, setNotificationsMessageList] = useState([]);
  const [notificationMesage, setNotificationMessage] = useState("");

  const [assignedTo, setAssignedTo] = useState({
    type: "",
    value: null,
    agentData: [],
    departmentData: [],
  });

  const roleBasedUrls = {
    agent: {
      extensionData: agentGetallUser.agent_get_all_user,
      departmentData: agentGetallQueue.agent_get_all_user,
      template: agentGetTemplate.agent_get_template,
    },
    admin: {
      extensionData: extensionList.extension_list,
      departmentData: allQueueList.allqueue_list,
      template: getEmailTemplate.get_email_template,
    },
    supervisor: {
      extensionData: supervisorGetallUser.supervisor_get_all_user,
      departmentData: supervisorGetallQueue.supervisor_get_all_user,
      template: supervisorgetEmailTemplate.supervisor_get_email_template,
    },
  };

  const { data: agentExtensionData } = useQuery(
    ["agent-extension-data"],
    () => instance.get(roleBasedUrls[userRole].extensionData),
    {
      onSuccess: (data) => {
        setAssignedTo((prev) => ({ ...prev, agentData: data?.data?.data }));
      },
    }
  );
  const { data: departmentData } = useQuery(
    ["agent-department-data"],
    () => instance.get(roleBasedUrls[userRole].departmentData),
    {
      onSuccess: (data) => {
        const departmentData =
          userRole === "admin" ? data?.data : data?.data?.data;

        setAssignedTo((prev) => ({
          ...prev,
          departmentData: departmentData || [],
        }));
      },
    }
  );

  //get email templates
  const initialTemplateType =
    formValue?.status === "closed" ? "SOLVED" : "PENDING";
  const [templateType, setTemplateType] = useState(initialTemplateType);

  const { template: templateUrl } =
    roleBasedUrls[userRole] || roleBasedUrls.agent;

  useEffect(() => {
    const mappedFollowUpStatus =
      formValue?.status === "closed" ? "SOLVED" : "PENDING";
    setTemplateType(mappedFollowUpStatus);
  }, [formValue?.status]);

  // Fetch email templates based on templateType
  const { data: getTemplate } = useQuery(["get-template", templateType], () => {
    return instance.get(`${templateUrl}?type=${templateType}`);
  });

  useEffect(() => {
    if (getTemplate) {
      const transformedNotifications = getTemplate?.data?.data?.map(
        (template) => ({
          _id: template._id,
          title: template.title,
          message: template.message,
        })
      );
      setNotificationsMessageList(transformedNotifications);
    }
  }, [getTemplate]);

  const handleChange = (e, name) => {
    const value =
      name === "sendNotification" ? e.target.checked : e.target.value;
    // Check if the status is being updated to 'solved'
    if (name === "status" && value === "closed") {
      setAssignedTo({
        ...assignedTo,
        type: "",
        selectedAgent: null,
        selectedDepartment: null,
      });
    }
    setFormValue((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      open: false,
      severity: "",
      message: "",
    });
  };

  const handleAgentSelectionChange = (event, newValue) => {
    setAssignedTo((prev) => ({
      ...prev,
      selectedAgent: newValue ? newValue.extension : null,
    }));
  };

  const handleDepartmentSelectionChange = (event, newValue) => {
    setAssignedTo((prev) => ({
      ...prev,
      selectedDepartment: newValue ? newValue._id : null,
    }));
  };

  const handleAgentCheckboxChange = (event) => {
    setAssignedTo((prev) => ({
      ...prev,
      selectedAgent: event.target.checked ? prev.selectedAgent : null,
      selectedDepartment: event.target.checked ? null : prev.selectedDepartment,
      type: event.target.checked ? "agent" : "",
    }));
  };

  const handleDepartmentCheckboxChange = (event) => {
    setAssignedTo((prev) => ({
      ...prev,
      selectedDepartment: event.target.checked ? prev.selectedDepartment : null,
      selectedAgent: event.target.checked ? null : prev.selectedAgent,
      type: event.target.checked ? "department" : "",
    }));
  };

  const handleSave = async () => {
    await updateTicketData();
    handleClosePickTicketPage();
    refetch();
  };

  const handleChatPreviewClick = (chat) => {
    setCurrentChat(chat);
    setChatModalOpen(true);
  };

  const handleSelectMessage = (message) => {
    setNotificationMessage(message);
  };

  const handleNotificationChange = (event) => {
    setSendNotification(event.target.checked);
    if (!event.target.checked) {
      setNotificationMessage("");
    } else {
      setIsNotificationModalOpen(true);
    }
  };
  const resetStateAndCloseModal = () => {
    setFormValue({
      remarks: "",
      status: "pending",
      sendNotification: false,
    });
    setAssignedTo({
      type: "",
      value: null,
      agentData: [],
      departmentData: [],
    });
    // Add other state resets as needed
    handleClosePickTicketPage(); // Close the modal
  };

  const updateTicketData = async () => {
    let payload = {
      remarks: formValue?.remarks,
      status: formValue?.status,
      sendNotification: sendNotification,
      notificationMessage: notificationMesage,
    };
    const adminUrl = `${adminupdateTicketStatus.admin_update_ticket_status}/${followData?._id}`;
    const agentUrl = `${updateTicketStatus.update_ticket_status}/${followData?._id}`;
    const supervisorApiUrl = `${supervisorupdateTicketStatus?.supervisor_update_ticket_status}/${followData?._id}`;
    const apiUrl =
      userRole === "admin"
        ? adminUrl
        : userRole === "agent"
        ? agentUrl
        : userRole === "supervisor"
        ? supervisorApiUrl
        : null;

    try {
      let response = await instance.patch(apiUrl, payload);
      if (response?.status === 200) {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Ticket Updated successfully",
        });
        setFormValue({
          remarks: "",
          status: "pending",
          sendNotification: false,
        });
      }
    } catch (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Error occured while creating ticket",
      });
    }
  };

  const updateAssignTicketAgent = async () => {
    let payload = {
      remarks: formValue?.remarks,
      extension: assignedTo?.selectedAgent,
    };
    const adminUrl = `${adminAssignTicket.admin_assigned_ticket}/${followData?._id}`;
    const agentUrl = `${agentAssignTicket.agent_assigned_ticket}/${followData?._id}`;
    const supervisorApiUrl = `${supervisorAssignTicket.supervisor_assigned_ticket}/${followData?._id}`;
    const apiUrl =
      userRole === "admin"
        ? adminUrl
        : userRole === "agent"
        ? agentUrl
        : userRole === "supervisor"
        ? supervisorApiUrl
        : null;

    try {
      let response = await instance.patch(apiUrl, payload);
      if (response?.status === 200) {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Ticket Assigned successfully",
        });
        resetStateAndCloseModal();
        refetch();
      }
    } catch (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Error occured while assigning ticket",
      });
    }
  };

  const updateAssignTicketDepartment = async () => {
    let payload = {
      remarks: formValue?.remarks,
      departmentId: assignedTo?.selectedDepartment,
    };
    const adminUrl = `${adminAssignTicketDepartment.admin_assigned_ticket_department}/${followData?._id}`;
    const agentUrl = `${agentAssignTicketDepartment.agent_assigned_ticket_department}/${followData?._id}`;
    const supervisorApiUrl = `${supervisorAssignTicketDepartment.supervisor_assigned_ticket_department}/${followData?._id}`;
    const apiUrl =
      userRole === "admin"
        ? adminUrl
        : userRole === "agent"
        ? agentUrl
        : userRole === "supervisor"
        ? supervisorApiUrl
        : null;

    try {
      let response = await instance.patch(apiUrl, payload);
      if (response?.status === 200) {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Ticket Assigned successfully",
        });
        resetStateAndCloseModal();
        refetch();
      }
    } catch (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Error occured while assigning ticket",
      });
    }
  };

  return (
    <>
      {isNotificationModalOpen && (
        <NotificationModal
          open={isNotificationModalOpen}
          handleClose={() => setIsNotificationModalOpen(false)}
          notifications={notificationsMessageList}
          onSelectMessage={handleSelectMessage}
        />
      )}

      <ChatPreviewModal
        isOpen={isChatModalOpen}
        onClose={() => {
          setChatModalOpen(false);
          setCurrentChat(null);
        }}
        chat={currentChat}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal}
        onClose={handleClosePickTicketPage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="flex-end" gap="50px">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="5px"
            >
              <Box display="flex" alignItems="center" gap="5px">
                <Person />
                <Typography variant="span">{followData?.clientName}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <Badge />
                <Typography variant="span">{followData?.clientId}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <Phone />
                <Typography variant="span">
                  {followData?.clientPhone}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <Email />
                <Typography variant="span">
                  {followData?.clientEmail}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <Typography variant="span">KYC Status:</Typography>
                <Typography variant="span">{followData?.kycStatus}</Typography>
              </Box>
            </Box>
            <Box>
              <PendingActions sx={{ color: "#E7625F", fontSize: "30px" }} />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="10px">
            <Card>
              <CardContent sx={{ marginTop: "1em" }}>
                <Box position="fixed" top="23px">
                  <Avatar
                    alt={followData?.clientName}
                    src={`https:kyc.naasasecurities.com.np/${followData?.clientImage}`}
                    sx={{ width: 100, height: 100 }}
                  />
                </Box>
                <Box>
                  <Box
                    color={"#E7625F"}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom="10px"
                  >
                    <Box display="flex" alignItems="center">
                      <Grid3x3 />
                      <Typography variant="span">
                        {followData?.ticketId}
                      </Typography>
                    </Box>

                    {followData?.type === "NORMAL" && (
                      <Tooltip
                        arrow
                        placement="top"
                        size="lg"
                        title="Play Call Recording"
                      >
                        <IconButton
                          onClick={() => handleAudioClick(followData?._id)}
                          sx={{ color: "#213555" }}
                          aria-label="play"
                        >
                          <PlayCircleFilledWhite />
                        </IconButton>
                      </Tooltip>
                    )}
                    {followData?.type === "VOICEMAIL" && (
                      <Tooltip
                        arrow
                        placement="top"
                        size="lg"
                        title="Play Voice Mail Recording"
                      >
                        <IconButton
                          onClick={() =>
                            handleVoiceMailAudioClick(
                              followData?.voicemailLogId
                            )
                          }
                          sx={{ color: "#765827" }}
                          aria-label="play"
                        >
                          <PlayCircleFilledWhite />
                        </IconButton>
                      </Tooltip>
                    )}
                    {followData?.type === "CHAT" && (
                      <Tooltip title="View Chat">
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleChatPreviewClick(followData?.chats)
                          }
                        >
                          <Visibility
                            sx={{ color: "#765827" }}
                            fontSize="medium"
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>

                  <Box
                    display="flex"
                    flexWrap="wrap"
                    fontSize="13px"
                    justifyContent="space-between"
                    gap="10px"
                  >
                    {followData?.isProxy && (
                      <>
                        <Box width="45%">
                          <Typography fontSize="18px" variant="span">
                            Proxy Name:{" "}
                          </Typography>
                          <Typography fontSize="18px" variant="span">
                            {followData?.proxyName}
                          </Typography>
                        </Box>
                      </>
                    )}
                    <Box width="45%">
                      <Typography fontSize="18px" variant="span">
                        Issue Category:{" "}
                      </Typography>
                      <Typography fontSize="18px" variant="span">
                        {followData?.issue?.name}
                      </Typography>
                    </Box>
                    <Box width="45%">
                      <Typography fontSize="18px" variant="span">
                        Sub Issue Category:{" "}
                      </Typography>
                      <Typography fontSize="18px" variant="span">
                        {followData?.sub_issue?.name}
                      </Typography>
                    </Box>

                    <Box width="45%">
                      <Typography fontSize="18px" variant="span">
                        Notification Status:
                      </Typography>
                      <Typography fontSize="18px" variant="span">
                        {followData?.notificationStatus}
                      </Typography>
                    </Box>
                    <Box width="100%">
                      <Box
                        display="flex"
                        flexDirection="column"
                        flexWrap="wrap"
                      >
                        {followData?.remarksList?.map((remark, index) => (
                          <>
                            <Box key={index} width="100%" marginTop={1}>
                              <Typography fontSize="18px" variant="span">
                                Remarks :
                              </Typography>
                              <Box
                                display="flex"
                                flexDirection="row"
                                marginTop=".5rem"
                                gap="10px"
                                justifyContent="space-between"
                              >
                                <Typography
                                  fontSize="18px"
                                  fontStyle="italic"
                                  variant="caption"
                                >
                                  {remark.remarks}
                                </Typography>
                                <Box marginTop=".5rem">
                                  <Typography
                                    fontSize="12px"
                                    fontStyle="italic"
                                    variant="caption"
                                    marginTop="4px"
                                  >
                                    {remark.updatedBy}
                                  </Typography>
                                  <Typography
                                    fontSize="12px"
                                    variant="caption"
                                    fontStyle="italic"
                                    marginTop="4px"
                                    marginLeft="9px"
                                  >
                                    {dayjs(remark.updatedAt).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
            <Box display="flex" marginTop="1rem" alignItems="center">
              <Typography variant="span" width="20%">
                Remarks #
              </Typography>
              <TextField
                variant="outlined"
                name="remarks"
                size="small"
                value={formValue?.remarks}
                onChange={(e) => handleChange(e, "remarks")}
                multiline
                rows={2}
                width="60%"
                fullWidth
                required
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              />
            </Box>
            <Box display="flex" gap="20px" width="100%" margin="1em 0">
              <Card sx={{ width: "100%", backgroundColor: "#f9f9f9" }}>
                <CardContent>
                  <Box display="flex" flexDirection="row" gap="2rem">
                    <Box>
                      <RadioGroup
                        row
                        name="status"
                        value={formValue.status}
                        onChange={(e) => handleChange(e, "status")}
                      >
                        <FormControlLabel
                          value="closed"
                          control={<Radio size="small" />}
                          label="Solved"
                        />
                        <FormControlLabel
                          value="pending"
                          control={<Radio size="small" />}
                          label="Pending"
                        />
                      </RadioGroup>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap="1.5rem"
                    >
                      <Typography variant="subtitle1">Assign To:</Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={assignedTo.type === "agent"}
                            onChange={handleAgentCheckboxChange}
                            disabled={formValue.status === "closed"}
                          />
                        }
                        label="Agent"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={assignedTo.type === "department"}
                            onChange={handleDepartmentCheckboxChange}
                            disabled={formValue.status === "closed"}
                          />
                        }
                        label="Department"
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    marginTop="1rem"
                    flexDirection="row"
                    gap="1rem"
                  >
                    {assignedTo.type === "agent" && (
                      <Autocomplete
                        options={assignedTo.agentData}
                        getOptionLabel={(option) =>
                          `${option.firstName} (${option.extension})`
                        }
                        value={
                          assignedTo.agentData.find(
                            (agent) =>
                              agent.extension === assignedTo.selectedAgent
                          ) || null
                        }
                        onChange={handleAgentSelectionChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Agent"
                            variant="outlined"
                            size="small"
                          />
                        )}
                        style={{ width: 300 }}
                        getOptionSelected={(option, value) =>
                          option.extension === value.extension
                        }
                      />
                    )}
                    {assignedTo.type === "department" && (
                      <Autocomplete
                        options={assignedTo.departmentData}
                        getOptionLabel={(option) => option.name}
                        value={
                          assignedTo.departmentData.find(
                            (department) =>
                              department._id === assignedTo.selectedDepartment
                          ) || null
                        }
                        onChange={handleDepartmentSelectionChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Department"
                            variant="outlined"
                            size="small"
                          />
                        )}
                        style={{ width: 300 }}
                        getOptionSelected={(option, value) =>
                          option._id === value._id
                        }
                      />
                    )}
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendNotification}
                            onChange={handleNotificationChange}
                          />
                        }
                        label="Notify User"
                      />
                      <Tooltip title="If you select 'Notify User', then providing a message is required.">
                        <Info
                          sx={{
                            marginBottom: "6px",
                            fontSize: "20px",
                            color: "#767676",
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
          <Box mt={3} display="flex" justifyContent="flex-end" gap="10px">
            {!assignedTo.selectedAgent && !assignedTo.selectedDepartment && (
              <Button
                variant="contained"
                color="primary"
                disabled={formValue?.remarks === ""}
                onClick={handleSave}
              >
                Update Ticket
              </Button>
            )}

            {/* Assign to Agent Button */}
            {assignedTo.type === "agent" && assignedTo.selectedAgent && (
              <Button
                variant="contained"
                color="secondary"
                disabled={formValue?.remarks === ""}
                onClick={updateAssignTicketAgent}
              >
                Assign to Agent
              </Button>
            )}

            {/* Assign to Department Button */}
            {assignedTo.type === "department" && assignedTo.selectedDepartment && (
              <Button
                variant="contained"
                color="secondary"
                onClick={updateAssignTicketDepartment}
                disabled={formValue?.remarks === ""}
              >
                Assign to Department
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ChatPickTicketModal;
