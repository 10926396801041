import React, { useState, useEffect } from "react";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { instance, socketURL } from "app/utils/axiosRequest";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import BreakModal from "./popupmodal";
import BreakEndModal from "./EndBreakModal";
import CircleIcon from "@mui/icons-material/Circle";
import HourglassDisabledIcon from "@mui/icons-material/HourglassDisabled";
import { breakButton } from "app/utils/api-urls/AgentApiUrls";
import socketIOClient from "socket.io-client";

const BreakStatus = () => {
  const userExtension = JSON.parse(localStorage.getItem("callCenterUser"));

  const [breakOptions, setBreakoptions] = useState("");

  const [statusbutton, setStatusbutton] = useState({
    is_in_break: true,
    reason: "",
  });

  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const [statusdata, setStatusdata] = useState({
    paused: true,
    reason: "Tea Break",
  });

  const [pausedstatusdata, setpausedstatusdata] = useState({
    paused: false,
  });

  const {
    data: data1,
    refetch: refetch1,
    fetch: fetch1,
    isRefetching: isRefetching1,
  } = useQuery(["extensionStatus"], () =>
    instance.get(breakButton.extension_status)
  );

  useEffect(() => {
    const accessToken = localStorage.getItem("callCenterToken");
    const socket = socketIOClient(socketURL.liveUrl, {
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    socket.on(`extension-status@${userExtension}`, (data) => {
      if (data) {
        setStatusbutton(data);
      }
    });
  }, []);

  const { data: data2, refetch: refetch2 } = useQuery(
    ["extensionstatuschanged"],
    () => instance.patch(breakButton.extension_pause, statusdata),
    {
      enabled: false,
    }
  );

  const { data: data3, refetch: refetch3 } = useQuery(
    ["extensionpausedstatus"],
    () => instance.patch(breakButton.extension_pause, pausedstatusdata),
    {
      enabled: false,
    }
  );

  const [modaldata, setModaldata] = useState({
    open: false,
  });

  const [modalenddata, setModalenddata] = useState({
    open: false,
  });

  const HandleOpenModel = () => {
    setModaldata({
      open: true,
    });
  };

  const HandleOpenEndModel = () => {
    setModalenddata({
      open: true,
    });
  };

  const handlemodalClose = () => {
    setModaldata({
      open: false,
    });
  };

  const handleendmodalClose = () => {
    setModalenddata({
      open: false,
    });
  };

  const handleBreakStatusChange = (event) => {
    setStatusdata({
      ...statusdata,
      [event.target.name]: event.target.value,
    });
  };

  const handlestartbreaksubmit = (e) => {
    refetch2();
    setModaldata({
      open: false,
    });
  };

  const handledbuttonsubmit = (e) => {
    refetch3();
    setModalenddata({
      open: false,
    });
  };

  useEffect(() => {
    if (data2) {
      if (data2?.status === 200) {
        setSnackBar({
          open: true,
          severity: "success",
          message: data2?.data?.status,
        });
      } else {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error ! please try later",
        });
      }
    }
  }, [data2]);

  useEffect(() => {
    if (data3) {
      if (data3?.status === 200) {
        setSnackBar({
          open: true,
          severity: "success",
          message: data3?.data?.status,
        });
      } else {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error ! please try later",
        });
      }
    }
  }, [data3]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  return (
    <Box display="flex" gap="10px">
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>

      <BreakModal
        open={modaldata.open}
        handlemodalclose={handlemodalClose}
        handleBreakStatusChange={handleBreakStatusChange}
        data={breakOptions.data}
        handlestartbreaksubmit={handlestartbreaksubmit}
        statusdata={statusdata}
      />
      <BreakEndModal
        openmodal={modalenddata.open}
        handleendmodalclose={handleendmodalClose}
        handledbuttonsubmit={handledbuttonsubmit}
      />

      {statusbutton?.is_in_break === false ? (
        <>
          <Button
            sx={{ borderColor: "#C26DBC", color: "#C26DBC" }}
            variant="outlined"
            size="small"
            startIcon={<HourglassBottomIcon />}
            onClick={HandleOpenModel}
          >
            Take a Break
          </Button>
        </>
      ) : (
        <>
          <Button
            sx={{ borderColor: "#C26DBC", color: "#C26DBC" }}
            variant="outlined"
            size="small"
          >
            {statusbutton?.reason}
          </Button>
          <Button
            sx={{ borderColor: "#FF0000", color: "#FF0000" }}
            variant="outlined"
            size="small"
            startIcon={<HourglassDisabledIcon />}
            onClick={HandleOpenEndModel}
          >
            End a Break
          </Button>
        </>
      )}
      <Box display="flex" alignItems="center">
        {statusbutton?.is_in_break === false ? (
          <>
            <CircleIcon
              sx={{
                color: "#00FF00", //green
                fontSize: "20px",
              }}
            />
          </>
        ) : (
          <>
            <CircleIcon
              sx={{
                color: "#FF0000", //red
                fontSize: "20px",
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default BreakStatus;
