import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Typography,
  Grid,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import CommonReportFilter from "../CommonReportFilter";
import CallHistoryTable from "./CallHistoryTable";
import { Download } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import CallSummary from "app/pages/dashboard/AdminDashboard/CallSummary";
import { adminCallHistoryReport } from "app/utils/api-urls/AdminAPiUrls";
import qs from "qs";

const CallHistoryReport = () => {
  const [filterData, setFilterData] = useState({
    extension: "",
    from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
    type: "",
    phone: "",
    page: 1,
    limit: 20,
  });

  //Fetch call history list
  const { data, isLoading, error, refetch, isRefetching } = useQuery(
    ["admin-callhistory-report", , filterData],
    () =>
      instance.get(
        adminCallHistoryReport.callhistory_list + "?" + qs.stringify(filterData)
      )
  );

  //fetch download csv files
  const {
    data: downloadData,
    refetch: downloadrefetch,
    isLoading: downloadloading,
    isRefetching: loadingfetching,
  } = useQuery(
    ["admin-d-report"],
    () => instance.post("report/call", filterData),
    {
      enabled: false,
    }
  );

  //pagination left click
  const handleLeftClick = () => {
    setFilterData({
      ...filterData,
      page: --filterData.page,
    });
    refetch();
  };
  //pagination right click
  const handleRightClick = () => {
    setFilterData({
      ...filterData,
      page: ++filterData.page,
    });
    refetch();
  };

  const handleFirstPageClick = () => {
    setFilterData({
      ...filterData,
      page: 1,
    });
    refetch();
  };

  const handleLastPageClick = () => {
    setFilterData({
      ...filterData,
      page: data?.data?.totalPages,
    });
    refetch();
  };

  const handleChange = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value,
      page: 1,
    });
  };

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      from: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      to: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleClear = () => {
    setFilterData({
      extension: "",
      from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
      type: "",
      phone: "",
      page: 1,
      limit: "20",
    });
    refetch();
  };

  const handleFilter = () => {
    setFilterData({
      ...filterData,
      page: 1,
    });
    refetch();
  };

  const callStatus = [
    {
      id: 1,
      value: "inbound",
      label: "Inbound",
    },
    {
      id: 2,
      value: "inbound-missed",
      label: "Inbound Missed",
    },

    {
      id: 3,
      value: "inbound-success",
      label: "Inbound Success",
    },
    {
      id: 4,
      value: "outbound",
      label: "Outbound",
    },
    {
      id: 5,
      value: "outbound-missed",
      label: "Outbound Missed",
    },

    {
      id: 6,
      value: "outbound-success",
      label: "Outbound Success",
    },
    {
      id: 7,
      value: "internal",
      label: "Internal",
    },
  ];

  return (
    <>
      <FullpageLoader open={isLoading} />

      <Box display="flex" gap="10px" width="100%" marginTop="1em">
        <Card sx={{ width: "25%" }}>
          <CardContent>
            <CommonReportFilter
              filterData={filterData}
              handleChange={handleChange}
              handleFromDate={handleFromDate}
              handleToDate={handleToDate}
              callStatusOption={callStatus}
              handleClear={handleClear}
              handleFilter={handleFilter}
              isLoading={isRefetching}
              reportType="callHistory"
            />
          </CardContent>
        </Card>
        <Box width="75%">
          <Card>
            <CardContent>
              <Box
                display="flex"
                flex="1"
                flexDirection="rows"
                justifyContent="space-between"
                marginBottom="5px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  gap="5px"
                  // backgroundColor="#59981A"
                  padding="2px"
                  color="#000000"
                  borderRadius="10px"
                  border="2px solid #285430"
                  height="2.5em"
                  width="10em"
                  justifyContent="center"
                >
                  {" "}
                  Count:- {data?.data?.totalData.toLocaleString()} Calls{" "}
                </Box>

                {/* <Box
                  display="flex"
                  sx={{
                    justifyContent: "flex-end",
                  }}
                  marginBottom="10px"
                >
                  <Button
                    variant="contained"
                    startIcon={<Download />}
                    onClick={handledownload}
                    isLoading={loadingfetching}
                  >
                    {isLoading ? <CircularProgress /> : "DOWNLOAD"}
                  </Button>
                </Box> */}
              </Box>

              <CallHistoryTable
                tableData={data?.data}
                tableDataLoading={isLoading}
                currentPage={filterData?.page}
                totalPage={data?.data?.totalPages}
                handleLeftClick={handleLeftClick}
                handleRightClick={handleRightClick}
                handleFirstPageClick={handleFirstPageClick}
                handleLastPageClick={handleLastPageClick}
              />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default CallHistoryReport;
