//Dashboard-Data
export const agentDashboardStats = {
  dashboard_stats: "agent/stats/agent",
};

//Ticket-list

export const ticketList = {
  ticket_list: "/agent/report/ticket",
  assigned_to_me: "/agent/report/assigned-ticket",
};

//Call-Report

export const CallReport = {
  call_report: "/agent/report/call",
  break_count: "extension/break-log-report",
};

//BreakButton

export const breakButton = {
  extension_status: "agent/extension-status",
  extension_pause: "agent/break",
};

///for agent category
export const agentcategoryList = {
  agent_category_list: "agent/issue",
};
///top 5 call history

export const topfivetickets = {
  top_five_tickets: "agent/tickets/phone",
};

//to update ticet status,remarks

export const updateTicketStatus = {
  update_ticket_status: "agent/solve-ticket",
};

//for chitchat tickets

export const chatticketsapi = {
  chat_tickets_api: "admin/chatTickets",
};

export const patchTicketStatus = {
  patch_ticket_status: "admin/ticketStatus",
};

//to listen recording for ticket
export const agentlistenRecording = {
  agent_listen_recording: "agent/recording/ticket",
};

//to listen recording of voice mail

export const agentlistenVoicemail = {
  agent_voicemail_recording: "agent/recording/voicemail",
};

export const agentpickTicketapi = {
  agent_pickedup_ticket_api: "agent/ticket/pick-ticket",
};

export const agentGetTemplate = {
  agent_get_template: "agent/template",
};

export const agentBreakCategory = {
  agent_break_category: "agent/breaks",
};

export const agentGetallUser = {
  agent_get_all_user: "agent/users",
};

export const agentGetallQueue = {
  agent_get_all_user: "agent/queues",
};

export const agentAssignTicket = {
  agent_assigned_ticket: "agent/assign-ticket",
};

export const agentAssignTicketDepartment = {
  agent_assigned_ticket_department: "agent/assign-ticket-department",
};

export const agentBulkTickets = {
  agent_bulk_tickets: "agent/bulk-ticket",
};
