import React, { useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import {
  AccountCircle,
  CalendarMonth,
  Category,
  ContactPhone,
  FilterList,
  Grid3x3,
  List,
  ManageAccounts,
  LocalPostOffice,
  RemoveCircleOutlineRounded,
} from "@mui/icons-material";

import TextfieldComponent from "app/components/Shared/Textfield/Textfield.component";
import DatePicker from "app/components/Shared/DatePicker/DatePicker.component";
import SelectComponent from "app/components/Shared/Select/Select.component";

const CommonReportFilter = ({
  handleChange,
  filterData,
  handleFromDate,
  handleToDate,
  callStatusOption,
  issueCategories,
  handleClear,
  handleFilter,
  reportType,
  breakCategoryOptions,
  ticketStatusOption,
  IssueOptions,
  LoginCategoryOptions,
  statusOptions,
  QueueCategoryOptions,
  isLoading,
  rolecategories,
  agentticketStatusOption,
  AgentIssueOptions,
  SupervisorcallstatusOptions,
  SupervisiorticketStatusOption,
  SupervisionIssueOptions,
  chatTicketsData,
  agentTicketType,
  supervisorAgentOptions,
  mode,
  reportTypeOptions,
  handleTypeChange,
  adminTicketType,
  queueOptions,
  supervisorTicketType,
  TicketType,
}) => {
  const [isHovered, setIsHovered] = useState({});
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <FilterList
          color="#C26DBC"
          sx={{ fontSize: "30px", color: "#C26DBC" }}
        />
        <Typography fontWeight={600} fontSize={20} variant="span">
          Filters
        </Typography>
      </Box>
      <Box padding="10px 5px" height="56vh" overflow="auto">
        {(reportType === "callHistory" ||
          reportType === "AgentStatusReport" ||
          reportType === "SupervisioncallHistory" ||
          reportType === "LogDetails" ||
          reportType === "BreakDetails") && (
          <>
            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, extension: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, extension: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {/* Extension Textfield Component */}
                <AccountCircle
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"Extension"}
                  handlechange={handleChange}
                  value={filterData.extension}
                  name={"extension"}
                  size="small"
                  color="primary"
                  fullWidth
                />
                {isHovered.extension && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({
                        target: { name: "extension", value: "" },
                      });
                    }}
                  />
                )}
              </CardContent>
            </Card>
          </>
        )}

        {reportType === "callHistory" && (
          <>
            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, type: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, type: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={callStatusOption}
                  value={filterData.type}
                  handleChange={handleChange}
                  label={"Call Status"}
                  name="type"
                />
                {isHovered.type && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "type", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>
            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, phone: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, phone: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ContactPhone sx={{ fontSize: "30px", color: "#C26DBC" }} />
                <TextfieldComponent
                  label={"Phone Number"}
                  handlechange={handleChange}
                  value={filterData.phone}
                  name={"phone"}
                  size="small"
                  color="primary"
                  fullWidth
                />
                {isHovered.phone && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "phone", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>
          </>
        )}

        {reportType === "SupervisioncallHistory" && (
          <>
            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, type: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, type: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={SupervisorcallstatusOptions}
                  value={filterData.type}
                  handleChange={handleChange}
                  label={"Call Status"}
                  name="type"
                />
                {isHovered.type && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "type", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>
            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, phone: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, phone: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ContactPhone sx={{ fontSize: "30px", color: "#C26DBC" }} />
                <TextfieldComponent
                  label={"Phone Number"}
                  handlechange={handleChange}
                  value={filterData.phone}
                  name={"phone"}
                  size="small"
                  color="primary"
                  fullWidth
                />
                {isHovered.phone && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "phone", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>
          </>
        )}

        {reportType === "AgentcallHistory" && (
          <>
            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, type: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, type: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={statusOptions}
                  value={filterData.type}
                  handleChange={handleChange}
                  label={"Call Status"}
                  name="type"
                />
                {isHovered.type && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "type", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>
            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, phone: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, phone: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ContactPhone sx={{ fontSize: "30px", color: "#C26DBC" }} />
                <TextfieldComponent
                  label={"Phone Number"}
                  handlechange={handleChange}
                  value={filterData.phone}
                  name={"phone"}
                  size="small"
                  color="primary"
                  fullWidth
                />
                {isHovered.phone && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "phone", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>
          </>
        )}

        {reportType === "BreakDetails" && (
          <Card sx={{ margin: "10px 0" }}>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <List
                color="#C26DBC"
                sx={{ fontSize: "30px", color: "#C26DBC" }}
              />
              <SelectComponent
                options={breakCategoryOptions}
                value={filterData.breakCategory}
                handleChange={handleChange}
                label={"Break Category"}
                name="breakCategory"
              />
            </CardContent>
          </Card>
        )}
        {(reportType === "HourlyReport" || reportType === "HoldTimeReport") && (
          <Card sx={{ margin: "10px 0" }}>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <List
                color="#C26DBC"
                sx={{ fontSize: "30px", color: "#C26DBC" }}
              />
              <SelectComponent
                options={QueueCategoryOptions}
                value={filterData.queue}
                handleChange={handleChange}
                label={"Queue Category"}
                name="queue"
              />
            </CardContent>
          </Card>
        )}

        {reportType === "ticketList" && (
          <>
            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, status: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, status: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={adminTicketType}
                  value={filterData?.type}
                  handleChange={handleChange}
                  label={"Type"}
                  name="type"
                />
              </CardContent>
            </Card>
            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, status: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, status: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={ticketStatusOption}
                  value={filterData.status}
                  handleChange={handleChange}
                  label={"Status"}
                  name="status"
                />
                {isHovered.status && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "status", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>

            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, issue: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, issue: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={IssueOptions}
                  value={filterData.issue}
                  handleChange={handleChange}
                  label={"Issue Category"}
                  name="issue"
                />
                {isHovered.issue && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "issue", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>

            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, extension: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, extension: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <AccountCircle
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"Opened By"}
                  handlechange={handleChange}
                  value={filterData.extension}
                  name={"extension"}
                  size="small"
                  color="primary"
                  fullWidth
                />
                {isHovered.extension && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({
                        target: { name: "extension", value: "" },
                      });
                    }}
                  />
                )}
              </CardContent>
            </Card>

            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, phone: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, phone: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ContactPhone
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"Client Phone No"}
                  handlechange={handleChange}
                  value={filterData.phone}
                  name={"phone"}
                  size="small"
                  color="primary"
                  fullWidth
                />
                {isHovered.phone && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "phone", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>
          </>
        )}

        {reportType === "SuperVisorticketList" && (
          <>
            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, status: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, status: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={supervisorTicketType}
                  value={filterData.type}
                  handleChange={handleChange}
                  label={"Ticket Type"}
                  name="type"
                />
              </CardContent>
            </Card>
            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, status: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, status: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={SupervisiorticketStatusOption}
                  value={filterData.status}
                  handleChange={handleChange}
                  label={"Status"}
                  name="status"
                />
                {isHovered?.status && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "status", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>

            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, issue: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, issue: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={supervisorAgentOptions}
                  value={filterData?.extension}
                  handleChange={handleChange}
                  label={"Opened By"}
                  name="extension"
                />
                {isHovered.extension && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({
                        target: { name: "extension", value: "" },
                      });
                    }}
                  />
                )}
              </CardContent>
            </Card>

            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, issue: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, issue: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />

                <SelectComponent
                  options={SupervisionIssueOptions}
                  value={filterData.issue}
                  handleChange={handleChange}
                  label={"Issue Category"}
                  name="issue"
                />
                {isHovered.issue && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "issue", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>
            {/* <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, extension: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, extension: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <AccountCircle
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"Opened By"}
                  handlechange={handleChange}
                  value={filterData.extension}
                  name={"extension"}
                  size="small"
                  color="primary"
                  fullWidth
                />
                {isHovered.extension && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({
                        target: { name: "extension", value: "" },
                      });
                    }}
                  />
                )}
              </CardContent>
            </Card> */}

            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, phone: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, phone: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ContactPhone
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"Client Phone No"}
                  handlechange={handleChange}
                  value={filterData.phone}
                  name={"phone"}
                  size="small"
                  color="primary"
                  fullWidth
                />
                {isHovered.phone && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "phone", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>
          </>
        )}

        {reportType === "AgentticketList" && (
          <>
            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={agentTicketType}
                  value={filterData.type}
                  handleChange={handleChange}
                  label={"Type"}
                  name="type"
                />
              </CardContent>
            </Card>

            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, status: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, status: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={agentticketStatusOption}
                  value={filterData.status}
                  handleChange={handleChange}
                  label={"Status"}
                  name="status"
                />

                {isHovered.status && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "status", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>

            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, issue: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, issue: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={AgentIssueOptions}
                  value={filterData.issue}
                  handleChange={handleChange}
                  label={"Issue Category"}
                  name="issue"
                />

                {isHovered.issue && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "issue", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>

            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, phone: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, phone: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ContactPhone
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"Client Phone No"}
                  handlechange={handleChange}
                  value={filterData.phone}
                  name={"phone"}
                  size="small"
                  color="primary"
                  fullWidth
                />

                {isHovered.phone && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "phone", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>
          </>
        )}

        {reportType === "AgentAssignedToMe" && (
          <>
            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={TicketType}
                  value={filterData.type}
                  handleChange={handleChange}
                  label={"Type"}
                  name="type"
                />
              </CardContent>
            </Card>

            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={ticketStatusOption}
                  value={filterData.status}
                  handleChange={handleChange}
                  label={"Status"}
                  name="status"
                />
              </CardContent>
            </Card>
            {/* <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Grid3x3
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"Ticket Id"}
                  handlechange={handleChange}
                  value={filterData.ticketId}
                  name={"ticketId"}
                  size="small"
                  color="primary"
                  fullWidth
                />
              </CardContent>
            </Card> */}
            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={IssueOptions}
                  value={filterData.issue}
                  handleChange={handleChange}
                  label={"Issue Category"}
                  name="issue"
                />
              </CardContent>
            </Card>

            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ContactPhone
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"Client Phone No"}
                  handlechange={handleChange}
                  value={filterData.phone}
                  name={"phone"}
                  size="small"
                  color="primary"
                  fullWidth
                />
              </CardContent>
            </Card>
          </>
        )}

        {reportType === "queueCallLogs" && (
          <>
            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, queue: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, queue: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <List
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <SelectComponent
                  options={queueOptions}
                  value={filterData.queue}
                  handleChange={handleChange}
                  label={"queue"}
                  name="queue"
                />
              </CardContent>
            </Card>

            <Card
              sx={{ margin: "10px 0" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, source: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, source: false }))
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ContactPhone
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"Source"}
                  handlechange={handleChange}
                  value={filterData.source}
                  name={"source"}
                  size="small"
                  color="primary"
                  fullWidth
                />
                {isHovered.source && (
                  <RemoveCircleOutlineRounded
                    color="#C26DBC"
                    sx={{
                      fontSize: "20px",
                      color: "#526D82",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange({ target: { name: "source", value: "" } });
                    }}
                  />
                )}
              </CardContent>
            </Card>
          </>
        )}

        {/* DATE PICKER COMPONENT */}

        {(reportType === "callHistory" ||
          reportType === "issueReports" ||
          reportType === "BreakDetails" ||
          reportType === "LogDetails" ||
          reportType === "HourlyReport" ||
          reportType === "AgentcallHistory" ||
          reportType === "AgentAssignedToMe" ||
          reportType === "AgentticketList" ||
          reportType === "SupervisioncallHistory" ||
          reportType === "SuperVisorticketList" ||
          reportType === "queueCallLogs" ||
          reportType === "ticketList") && (
          <>
            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CalendarMonth
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <DatePicker
                  label={"From Date"}
                  value={filterData.from}
                  name={"from"}
                  handleChange={handleFromDate}
                />
              </CardContent>
            </Card>
            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CalendarMonth
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <DatePicker
                  label={"To"}
                  value={filterData.to}
                  name={"to"}
                  handleChange={handleToDate}
                />
              </CardContent>
            </Card>
          </>
        )}
        {reportType === "issueReports" && (
          <Card sx={{ margin: "10px 0" }}>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Category sx={{ fontSize: "30px", color: "#C26DBC" }} />
              <SelectComponent
                options={issueCategories}
                value={filterData.issueCategory}
                handleChange={handleChange}
                label={"Issue Category"}
                name="issueCategory"
              />
            </CardContent>
          </Card>
        )}
        {reportType === "usersetting" && (
          <>
            {/* Role */}
            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ManageAccounts sx={{ fontSize: "30px", color: "#C26DBC" }} />
                <SelectComponent
                  options={rolecategories}
                  value={filterData.role}
                  handleChange={handleChange}
                  label={"Role"}
                  name="role"
                />
              </CardContent>
            </Card>
            {/* Name */}
            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {/* Extension Textfield Component */}
                <AccountCircle
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"Name"}
                  handlechange={handleChange}
                  value={filterData.name}
                  name={"name"}
                  size="small"
                  color="primary"
                  fullWidth
                />
              </CardContent>
            </Card>
            {/* Email */}
            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {/* Extension Textfield Component */}
                <LocalPostOffice
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"E-Mail"}
                  handlechange={handleChange}
                  value={filterData.email}
                  name={"email"}
                  size="small"
                  color="primary"
                  fullWidth
                />
              </CardContent>
            </Card>
            {/* Mobile Number */}
            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {/* Extension Textfield Component */}
                <ContactPhone
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"Mobile Number"}
                  handlechange={handleChange}
                  value={filterData.mobileNumber}
                  name={"mobileNumber"}
                  size="small"
                  color="primary"
                  fullWidth
                />
              </CardContent>
            </Card>
          </>
        )}
        {reportType === "AgentChatTicketList" && (
          <>
            {/* Name */}
            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {/* Extension Textfield Component */}
                <AccountCircle
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"Name"}
                  handlechange={handleChange}
                  value={chatTicketsData.name}
                  name={"name"}
                  size="small"
                  color="primary"
                  fullWidth
                />
              </CardContent>
            </Card>

            {/* Mobile Number */}
            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {/* Extension Textfield Component */}
                <ContactPhone
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <TextfieldComponent
                  label={"Mobile Number"}
                  handlechange={handleChange}
                  value={chatTicketsData.phoneNumber}
                  name={"phoneNumber"}
                  size="small"
                  color="primary"
                  fullWidth
                />
              </CardContent>
            </Card>
          </>
        )}
        {reportType === "HoldTimeReport" && (
          <>
            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CalendarMonth
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <DatePicker
                  label={"From Date"}
                  value={filterData.startDate}
                  name={"from"}
                  handleChange={handleFromDate}
                />
              </CardContent>
            </Card>
            <Card sx={{ margin: "10px 0" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CalendarMonth
                  color="#C26DBC"
                  sx={{ fontSize: "30px", color: "#C26DBC" }}
                />
                <DatePicker
                  label={"To"}
                  value={filterData.endDate}
                  name={"to"}
                  handleChange={handleToDate}
                />
              </CardContent>
            </Card>
          </>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" gap="10px" marginTop=".5em">
        <Button
          sx={{ backgroundColor: "#696969" }}
          variant="contained"
          onClick={handleClear}
        >
          Clear
        </Button>
        <Button
          sx={{ backgroundColor: "#C26DBC" }}
          variant="contained"
          onClick={!isLoading ? handleFilter : null}
        >
          {isLoading ? <CircularProgress size="1.5em" /> : "Filter"}
        </Button>
      </Box>
    </Box>
  );
};

export default CommonReportFilter;
