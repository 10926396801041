import {
  Person,
  Badge,
  PendingActions,
  CheckCircle,
  Grid3x3,
  Phone,
  PlayCircleFilledWhite,
  FileOpenOutlined,
  Visibility,
  Email,
} from "@mui/icons-material";
import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import ReactAudioPlayer from "react-audio-player";
import {
  agentlistenRecording,
  agentlistenVoicemail,
} from "app/utils/api-urls/AgentApiUrls";
import { instance } from "app/utils/axiosRequest";
import {
  adminlistenRecording,
  adminlistenVoicemail,
} from "app/utils/api-urls/AdminAPiUrls";
import {
  supervisorlistenRecording,
  supervisorlistenVoicemail,
} from "app/utils/api-urls/SupervisorAPiUrls";
import ChatPreviewModal from "app/pages/dashboard/ChatViewModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxHeight: 600,
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  borderRadius: 2,
};

const ViewTicketModal = ({ open, handleClose, data }) => {
  const userRole = localStorage.getItem("role");

  const [showPlayer, setShowPlayer] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const [selectedRecordingId, setSelectedRecordingId] = useState(null);
  const [loadingAudio, setLoadingAudio] = useState(false);
  const [isChatModalOpen, setChatModalOpen] = useState(false);
  const [currentChat, setCurrentChat] = useState(null);

  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    //to reset src after modal is not on
    if (!open) {
      setAudioSrc(null);
      setSelectedRecordingId(null);
      setShowPlayer(false);
    }
  }, [open]);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  const handleAudioClick = async (recordingId) => {
    try {
      setLoadingAudio(true);
      if (selectedRecordingId === recordingId) {
        setSelectedRecordingId(null);
        setAudioSrc(null);
        setShowPlayer(false);
      } else {
        const adminApiURL = `${adminlistenRecording.admin_listen_recording}/${recordingId}`;
        const agentApiURL = `${agentlistenRecording.agent_listen_recording}/${recordingId}`;
        const supervisorApiUrl = `${supervisorlistenRecording.supervisor_listen_recording}/${recordingId}`;
        const apiUrl =
          userRole === "admin"
            ? adminApiURL
            : userRole === "agent"
            ? agentApiURL
            : userRole === "supervisor"
            ? supervisorApiUrl
            : null;

        const response = await instance.get(apiUrl, {
          responseType: "arraybuffer",
        });

        const blob = new Blob([response.data], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(blob);

        setSelectedRecordingId(recordingId);
        setAudioSrc(audioUrl);
        setShowPlayer(true); // Show player
      }
      setLoadingAudio(false);
    } catch (error) {
      setLoadingAudio(false);
      setSnackBar({
        open: true,
        message: "Error fetching audio recording.",
      });
    }
  };

  const handleVoiceMailAudioClick = async (recordingId) => {
    try {
      setLoadingAudio(true);
      if (selectedRecordingId === recordingId) {
        setSelectedRecordingId(null);
        setAudioSrc(null);
        setShowPlayer(false);
      } else {
        const adminApiURL = `${adminlistenVoicemail.admin_voicemail_recording}/${recordingId}`;
        const agentApiURL = `${agentlistenVoicemail.agent_voicemail_recording}/${recordingId}`;
        const supervisorApiUrl = `${supervisorlistenVoicemail.supervisor_voicemail_recording}/${recordingId}`;
        const apiUrl =
          userRole === "admin"
            ? adminApiURL
            : userRole === "agent"
            ? agentApiURL
            : userRole === "supervisor"
            ? supervisorApiUrl
            : null;

        const response = await instance.get(apiUrl, {
          responseType: "arraybuffer",
        });

        const blob = new Blob([response.data], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(blob);

        setSelectedRecordingId(recordingId);
        setAudioSrc(audioUrl);
        setShowPlayer(true); // Show player
      }
      setLoadingAudio(false);
    } catch (error) {
      setLoadingAudio(false);
      setSnackBar({
        open: true,
        message: "Error fetching audio recording.",
      });
    }
  };

  const handleChatPreviewClick = (chat) => {
    setCurrentChat(chat);
    setChatModalOpen(true);
  };

  return (
    <>
      <ChatPreviewModal
        isOpen={isChatModalOpen}
        onClose={() => {
          setChatModalOpen(false);
          setCurrentChat(null);
        }}
        chat={currentChat}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="flex-end" gap="50px">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="10px"
            >
              <Box display="flex" alignItems="center" gap="5px">
                <Person />
                <Typography variant="span">{data?.clientName}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <Badge />
                <Typography variant="span">{data?.clientId}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <Phone />
                <Typography variant="span">{data?.clientPhone}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <Email />
                <Typography variant="span">{data?.clientEmail}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <Typography variant="span">KYC Status:</Typography>
                <Typography variant="span">{data?.kycStatus}</Typography>
              </Box>
            </Box>

            <Box>
              {data?.status === "pending" ? (
                <PendingActions sx={{ color: "#E7625F", fontSize: "30px" }} />
              ) : data?.status === "open" ? (
                <FileOpenOutlined sx={{ color: "#27374D", fontSize: "30px" }} />
              ) : (
                <CheckCircle sx={{ color: "#81B622", fontSize: "30px" }} />
              )}
            </Box>
          </Box>
          <Card sx={{ marginTop: "1em" }}>
            <CardContent sx={{ marginTop: "1em" }}>
              <Box position="fixed" top="21px">
                <Avatar
                  src={`https:kyc.naasasecurities.com.np/${data?.clientImage}`}
                  alt={data?.clientName}
                  sx={{ width: 100, height: 100 }}
                />
              </Box>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  color={
                    data?.status === "pending"
                      ? "#E7625F"
                      : data?.status === "open"
                      ? "#303481"
                      : "#81B622"
                  }
                  marginBottom="10px"
                >
                  <Box display="flex" alignItems="center">
                    <Grid3x3 />
                    <Typography variant="span">{data?.ticketId}</Typography>
                  </Box>
                  {loadingAudio ? (
                    <CircularProgress />
                  ) : showPlayer ? (
                    <ReactAudioPlayer
                      controls
                      src={audioSrc}
                      autoPlay
                      style={{ height: "40px" }}
                    />
                  ) : null}
                  {data?.type === "NORMAL" && (
                    <Tooltip
                      arrow
                      placement="top"
                      size="lg"
                      title="Play Call Recording"
                    >
                      <IconButton
                        onClick={() => handleAudioClick(data?._id)}
                        sx={{ color: "#213555" }}
                        aria-label="play"
                      >
                        <PlayCircleFilledWhite />
                      </IconButton>
                    </Tooltip>
                  )}
                  {data?.type === "VOICEMAIL" && (
                    <Tooltip
                      arrow
                      placement="top"
                      size="lg"
                      title="Play Voice Mail Recording"
                    >
                      <IconButton
                        onClick={() =>
                          handleVoiceMailAudioClick(data?.voicemailLogId)
                        }
                        sx={{ color: "#765827" }}
                        aria-label="play"
                      >
                        <PlayCircleFilledWhite />
                      </IconButton>
                    </Tooltip>
                  )}
                  {data?.type === "CHAT" && (
                    <Tooltip title="View Chat">
                      <IconButton
                        size="small"
                        onClick={() => handleChatPreviewClick(data?.chats)}
                      >
                        <Visibility
                          sx={{ color: "#765827" }}
                          fontSize="medium"
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  fontSize="13px"
                  justifyContent="space-between"
                  gap="15px"
                >
                  {data?.isProxy && (
                    <Box width="45%" display="flex" alignItems="center">
                      <Typography
                        fontSize="16px"
                        variant="span"
                        sx={{ marginRight: "4px", fontWeight: "bold" }}
                      >
                        Proxy Name:
                      </Typography>
                      <Typography fontSize="16px" variant="span">
                        {data?.issue?.proxyName}
                      </Typography>
                    </Box>
                  )}

                  <Box width="45%" display="flex" alignItems="center">
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Issue Category:
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {data?.issue?.name}
                    </Typography>
                  </Box>

                  <Box width="45%" display="flex" alignItems="center">
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Sub Issue Category:
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {data?.sub_issue?.name}
                    </Typography>
                  </Box>

                  <Box width="45%" display="flex" alignItems="center">
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Opened By:
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {data?.openedBy}
                    </Typography>
                  </Box>

                  <Box width="45%" display="flex" alignItems="center">
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Notification Status:
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {data?.notificationStatus}
                    </Typography>
                  </Box>

                  <Box width="45%" display="flex" alignItems="center">
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Queue:
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {data?.issue?.queues?.name}
                    </Typography>
                  </Box>

                  {/* //multiple remarks */}
                  <Box width="100%">
                    <Box display="flex" flexDirection="column">
                      <Typography fontSize="18px" variant="span">
                        Remarks:
                      </Typography>
                      {data?.remarksList?.map((remark, index) => (
                        <Card sx={{ marginTop: "1em" }}>
                          <CardContent>
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="1px"
                            >
                              <Typography fontSize="18px" variant="span">
                                {remark.remarks}
                              </Typography>
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                gap="10px"
                              >
                                <Typography
                                  fontSize="12px"
                                  fontStyle="italic"
                                  variant="caption"
                                >
                                  {remark.updatedBy}
                                </Typography>
                                <Typography
                                  fontSize="12px"
                                  variant="caption"
                                  fontStyle="italic"
                                >
                                  {dayjs(remark.updatedAt).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default ViewTicketModal;
