import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import {
  CallMade,
  CallReceived,
  ContactPhone,
  Phone,
} from "@mui/icons-material";
import AgentTableFilter from "app/components/AgentReportTable/AgentTableFilter";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CircleIcon from "@mui/icons-material/Circle";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import dayjs from "dayjs";
import { CallReport } from "app/utils/api-urls/AgentApiUrls";
import qs from "qs";
import CommonReportFilter from "../AdminDashboard/CommonReportFilter";
import eventEmitter from "../../event-emitter/eventEmitter";
import CustomPagination from "app/shared/Pagination/Pagination.component";

const AgentReport = () => {
  const [filterData, setFilterData] = useState({
    extension: "",
    from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
    type: "",
    phone: "",
    page: 1,
    limit: 20,
  });

  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState("");

  const { isLoading, data, refetch, isRefetching } = useQuery(
    [filterData, "agenttable_list"],
    () => instance.get(CallReport.call_report + "?" + qs.stringify(filterData))
  );

  const columns = [
    {
      field: "type",
      headerName: "Call Type",
      width: 150,
      renderCell: (cell) => {
        let status = cell?.row?.source?.length > 4 ? "inbound" : "outbound";
        return (
          <Box
            display="flex"
            alignItems="center"
            gap="5px"
            backgroundColor={
              status === "inbound"
                ? "#187498"
                : status === "outbound"
                ? "#EF7C8E"
                : "#0E86D4"
            }
            padding="2px"
            color="#fff"
            borderRadius="10px"
            height="2em"
            width="7em"
            justifyContent="center"
          >
            {status === "outbound" ? (
              <>
                <CallMade sx={{ fontSize: "12px" }} />
                <Typography fontSize="12px" variant="span">
                  Outbound
                </Typography>
              </>
            ) : status === "inbound" ? (
              <>
                <CallReceived sx={{ fontSize: "12px" }} />
                <Typography fontSize="12px" variant="span">
                  Inbound
                </Typography>
              </>
            ) : (
              <>
                <PhoneForwarded sx={{ fontSize: "12px" }} />
                <Typography fontSize="12px" variant="span">
                  Transfered
                </Typography>
              </>
            )}
          </Box>
        );
      },
    },
    {
      field: "disposition",
      headerName: "Status",

      width: 120,
      renderCell: (cell) => {
        let status = cell?.row?.disposition;
        return (
          <Box
            display="flex"
            alignItems="center"
            gap="5px"
            backgroundColor={
              status === "ANSWERED"
                ? "#7AA874"
                : status === "NO ANSWER"
                ? "#FD5D5D"
                : "#675D50"
            }
            padding="2px"
            color="#fff"
            borderRadius="10px"
            height="2em"
            width="7em"
            justifyContent="center"
          >
            {status === "ANSWERED" ? (
              <>
                <Phone sx={{ fontSize: "12px" }} />
                <Typography fontSize="12px" variant="span">
                  Answered
                </Typography>
              </>
            ) : status === "NO ANSWER" ? (
              <>
                <Phone sx={{ fontSize: "12px" }} />
                <Typography fontSize="12px" variant="span">
                  No Answered
                </Typography>
              </>
            ) : (
              <>
                <Phone sx={{ fontSize: "12px" }} />
                <Typography fontSize="12px" variant="span">
                  Busy
                </Typography>
              </>
            )}
          </Box>
        );
      },
    },

    {
      field: "source",
      headerName: "Source",
      width: 200,
      renderCell: (cell) => {
        const phoneNumber = cell?.row?.source;
        const type = cell?.row?.type;

        return (
          <Box display="flex" alignItems="center" gap="10px">
            <Tooltip placement="top" title="Click to call or copy">
              <Typography
                fontSize="14px"
                component="span"
                onClick={(event) => handleOpenMenu(event, phoneNumber)}
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                {phoneNumber}
              </Typography>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "destination",
      headerName: "Destination",
      width: 200,
      renderCell: (cell) => {
        const phoneNumber = cell?.row?.destination;
        const type = cell?.row?.type;

        return (
          <Box display="flex" alignItems="center" gap="10px">
            <Tooltip placement="top" title="Click to call or copy">
              <Typography
                fontSize="14px"
                component="span"
                onClick={(event) => handleOpenMenu(event, phoneNumber)}
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                {phoneNumber}
              </Typography>
            </Tooltip>
          </Box>
        );
      },
    },

    {
      field: "createdAt",
      headerName: "Date",
      width: 150,
      renderCell: (cell) => {
        const date = cell?.row?.createdAt;
        const newDate = dayjs(date).format("MMMM DD YYYY");
        return (
          <Typography fontSize="14px" variant="span">
            {newDate}
          </Typography>
        );
      },
    },

    {
      field: "time",
      headerName: "Time",
      width: 150,
      renderCell: (cell) => {
        const time = cell?.row?.createdAt;
        const dateObject = dayjs(time);
        const formattedTime = dateObject.format("HH:mm:ss");

        return (
          <Typography fontSize="14px" variant="span">
            {formattedTime}
          </Typography>
        );
      },
    },

    {
      field: "callTime",
      headerName: "Duration",
      width: 150,
      renderCell: (cell) => {
        const duration = cell?.row?.callTime;
        const formattedTime = dayjs()
          .startOf("day")
          .second(duration)
          .format("H:mm:ss");

        return (
          <Box
            display="flex"
            alignItems="center"
            gap="5px"
            padding="2px"
            justifyContent="center"
          >
            <>
              <Box>
                <Typography color="#0000FF" fontSize="15px" variant="span">
                  {formattedTime}
                </Typography>
              </Box>
            </>
          </Box>
        );
      },
    },
  ];
  //pagination left click
  const handleLeftClick = () => {
    setFilterData({
      ...filterData,
      page: --filterData.page,
    });
    refetch();
  };
  //pagination right click
  const handleRightClick = () => {
    setFilterData({
      ...filterData,
      page: ++filterData.page,
    });
    refetch();
  };

  const handleFirstPageClick = () => {
    setFilterData({
      ...filterData,
      page: 1,
    });
    refetch();
  };

  const handleLastPageClick = () => {
    setFilterData({
      ...filterData,
      page: data?.data?.totalPages,
    });
    refetch();
  };

  const handleChange = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      from: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      to: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFilter = () => {
    refetch();
  };

  const handleClear = () => {
    setFilterData({
      extension: "",
      from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
      type: "",
      phone: "",
      page: 1,
      limit: "20",
    });
    refetch();
  };

  // Opens a menu when a phone number is clicked
  const handleOpenMenu = (event, phoneNumber) => {
    setAnchorEl(event.currentTarget);
    setSelectedNumber(phoneNumber);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleCopyNumber = () => {
    navigator.clipboard.writeText(selectedNumber);
    handleCloseMenu();
  };

  const handleCallNumber = () => {
    handleCallClick(selectedNumber);
    handleCloseMenu();
  };

  // Handles the call event for a given phone number.
  // It triggers the external makeCall function and emits an event.
  const handleCallClick = (phoneNumber) => {
    if (typeof window.makeCall === "function") {
      window.makeCall(phoneNumber);
    } else {
      console.error("makeCall function not found");
    }

    // Emit the event to inform callclicked event
    eventEmitter.emit("callClicked");
  };

  const agentcallstatusOptions = [
    {
      id: 1,
      value: "inbound",
      label: "Inbound",
    },
    {
      id: 2,
      value: "inbound-missed",
      label: "Inbound Missed",
    },

    {
      id: 3,
      value: "inbound-success",
      label: "Inbound Success",
    },
    {
      id: 4,
      value: "outbound",
      label: "Outbound",
    },
    {
      id: 5,
      value: "outbound-missed",
      label: "Outbound Missed",
    },

    {
      id: 6,
      value: "outbound-success",
      label: "Outbound Success",
    },
  ];

  return (
    <>
      <FullpageLoader open={isLoading || isRefetching} />
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleCopyNumber}>Copy</MenuItem>
        <MenuItem onClick={handleCallNumber}>Call</MenuItem>
      </Menu>
      <Box display="flex" gap="10px" width="100%" marginTop="1em">
        <Card sx={{ width: "25%" }}>
          <CardContent>
            <CommonReportFilter
              filterData={filterData}
              handleChange={handleChange}
              handleFromDate={handleFromDate}
              handleToDate={handleToDate}
              statusOptions={agentcallstatusOptions}
              handleClear={handleClear}
              handleFilter={handleFilter}
              isLoading={isRefetching}
              reportType="AgentcallHistory"
            />
          </CardContent>
        </Card>

        <Box //table for call details
          width="75%"
        >
          <Card>
            <CardContent>
              <Box sx={{ height: "67vh", width: "100%" }}>
                {!isLoading && data && (
                  <>
                    <DataGrid
                      getRowId={(row) => row._id}
                      rows={data?.data?.data}
                      columns={columns}
                      hideFooter="true"
                    />
                    <CustomPagination
                      currentPage={filterData?.page}
                      totalPage={data?.data?.totalPages}
                      handleLeftClick={handleLeftClick}
                      handleRightClick={handleRightClick}
                      handleFirstPageClick={handleFirstPageClick}
                      handleLastPageClick={handleLastPageClick}
                    />
                  </>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default AgentReport;
