import React from "react";
import { Box, Typography } from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  FirstPage,
  LastPage,
} from "@mui/icons-material";

const CustomPagination = ({
  currentPage,
  totalPage,
  handleLeftClick,
  handleRightClick,
  handleFirstPageClick,
  handleLastPageClick,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      fontSize="12px"
      marginRight="4rem"
      gap="1px"
    >
      <FirstPage
        sx={{
          color: currentPage === 1 ? "#d3d3d3" : "black",
          fontSize: "14px",
          cursor: "pointer",
        }}
        onClick={currentPage === 1 ? null : handleFirstPageClick}
      />
      <KeyboardArrowLeft
        sx={{
          color: currentPage === 1 ? "#d3d3d3" : "black",
          fontSize: "14px",
          cursor: "pointer",
        }}
        onClick={currentPage === 1 ? null : handleLeftClick}
      />
      <Typography variant="span">
        Page {currentPage} of {totalPage}
      </Typography>
      <KeyboardArrowRight
        sx={{
          color: currentPage === totalPage ? "#d3d3d3" : "black",
          fontSize: "14px",
          cursor: "pointer",
        }}
        onClick={currentPage === totalPage ? null : handleRightClick}
      />
      <LastPage
        sx={{
          color: currentPage === totalPage ? "#d3d3d3" : "black",
          fontSize: "14px",
          cursor: "pointer",
        }}
        onClick={currentPage === totalPage ? null : handleLastPageClick}
      />
    </Box>
  );
};

export default CustomPagination;
