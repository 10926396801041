import {
  CallMade,
  CallReceived,
  Pause,
  Phone,
  PhoneForwarded,
  PlayArrow,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "app/shared/Pagination/Pagination.component";
import React, { useEffect, useRef } from "react";
import dayjs from "dayjs";
import { useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { instance } from "app/utils/axiosRequest";
import { listenRecording } from "app/utils/api-urls/AdminAPiUrls";
import eventEmitter from "../../../event-emitter/eventEmitter";

const CallHistoryTable = ({
  tableData,
  tableDataLoading,
  currentPage,
  handleLeftClick,
  handleRightClick,
  handleFirstPageClick,
  handleLastPageClick,
}) => {
  const [selectedRecordingId, setSelectedRecordingId] = useState(null);
  const [audioLoading, setAudioLoading] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState("");

  // Handles the playing of the audio for a given recording ID.
  // If the audio is already playing, it pauses it.
  const handleAudioClick = async (recordingId) => {
    try {
      setAudioLoading(true); // Start loading
      if (selectedRecordingId === recordingId) {
        // If the same recording is clicked again, pause the audio
        setSelectedRecordingId(null);
        setAudioSrc(null);
      } else {
        const response = await instance.get(
          `${listenRecording.listen_recording}/${recordingId}`,
          {
            responseType: "arraybuffer",
          }
        );

        const blob = new Blob([response.data], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(blob);

        setSelectedRecordingId(recordingId);
        setAudioSrc(audioUrl);
      }
    } catch (error) {
      console.error("Error fetching audio recording:", error);
    } finally {
      setAudioLoading(false); // End loading
    }
  };

  const columns = [
    {
      field: "type",
      headerName: "Call Type",
      width: 150,
      renderCell: (cell) => {
        let status = cell?.row?.type;
        return (
          <Box
            display="flex"
            alignItems="center"
            gap="5px"
            backgroundColor={
              status === "inbound"
                ? "#187498"
                : status === "outbound"
                ? "#EF7C8E"
                : "#0E86D4"
            }
            padding="2px"
            color="#fff"
            borderRadius="10px"
            height="2em"
            width="7em"
            justifyContent="center"
          >
            {status === "outbound" ? (
              <>
                <CallMade sx={{ fontSize: "12px" }} />
                <Typography fontSize="12px" variant="span">
                  Outbound
                </Typography>
              </>
            ) : status === "inbound" ? (
              <>
                <CallReceived sx={{ fontSize: "12px" }} />
                <Typography fontSize="12px" variant="span">
                  Inbound
                </Typography>
              </>
            ) : (
              <>
                <PhoneForwarded sx={{ fontSize: "12px" }} />
                <Typography fontSize="12px" variant="span">
                  Transfered
                </Typography>
              </>
            )}
          </Box>
        );
      },
    },
    {
      field: "disposition",
      headerName: "Status",

      width: 120,
      renderCell: (cell) => {
        let status = cell?.row?.disposition;
        return (
          <Box
            display="flex"
            alignItems="center"
            gap="5px"
            backgroundColor={
              status === "ANSWERED"
                ? "#7AA874"
                : status === "NO ANSWER"
                ? "#FD5D5D"
                : "#675D50"
            }
            padding="2px"
            color="#fff"
            borderRadius="10px"
            height="2em"
            width="7em"
            justifyContent="center"
          >
            {status === "ANSWERED" ? (
              <>
                <Phone sx={{ fontSize: "12px" }} />
                <Typography fontSize="12px" variant="span">
                  Answered
                </Typography>
              </>
            ) : status === "NO ANSWER" ? (
              <>
                <Phone sx={{ fontSize: "12px" }} />
                <Typography fontSize="12px" variant="span">
                  No Answered
                </Typography>
              </>
            ) : (
              <>
                <Phone sx={{ fontSize: "12px" }} />
                <Typography fontSize="12px" variant="span">
                  Busy
                </Typography>
              </>
            )}
          </Box>
        );
      },
    },

    {
      field: "source",
      headerName: "Source",
      width: 200,
      renderCell: (cell) => {
        const phoneNumber = cell?.row?.source;

        return (
          <Box display="flex" alignItems="center" gap="10px">
            <Tooltip placement="top" title="Click to call or copy">
              <Typography
                fontSize="14px"
                component="span"
                onClick={(event) => handleOpenMenu(event, phoneNumber)}
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                {phoneNumber}
              </Typography>
            </Tooltip>
          </Box>
        );
      },
    },

    {
      field: "destination",
      headerName: "Destination",
      width: 200,
      renderCell: (cell) => {
        const phoneNumber = cell?.row?.destination;

        return (
          <Box display="flex" alignItems="center" gap="10px">
            <Tooltip placement="top" title="Click to call or copy">
              <Typography
                fontSize="14px"
                component="span"
                onClick={(event) => handleOpenMenu(event, phoneNumber)}
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                {phoneNumber}
              </Typography>
            </Tooltip>
          </Box>
        );
      },
    },

    {
      field: "createdAt",
      headerName: "Date",
      width: 150,
      renderCell: (cell) => {
        const date = cell?.row?.createdAt;
        const newDate = dayjs(date).format("MMMM DD YYYY");
        return (
          <Typography fontSize="14px" variant="span">
            {newDate}
          </Typography>
        );
      },
    },
    {
      field: "time",
      headerName: "Time",
      width: 150,
      renderCell: (cell) => {
        const time = cell?.row?.createdAt;
        const dateObject = dayjs(time);
        const formattedTime = dateObject.format("HH:mm:ss");

        return (
          <Typography fontSize="14px" variant="span">
            {formattedTime}
          </Typography>
        );
      },
    },

    {
      field: "callTime",
      headerName: "Duration",
      width: 100,
      renderCell: (cell) => {
        const duration = cell?.row?.callTime;
        const formattedTime = dayjs()
          .startOf("day")
          .second(duration)
          .format("H:mm:ss");
        return <>{formattedTime}</>;
      },
    },

    {
      field: "recording",
      headerName: "Recording",
      width: 200,
      renderCell: (cell) => {
        const recordingId = cell?.row?._id;
        const isPlaying = selectedRecordingId === recordingId;
        const type = cell?.row?.type;
        const disposition = cell?.row?.disposition;

        const isTypeInboundAndAnswered =
          type === "inbound" && disposition === "ANSWERED";
        return (
          <Box display="flex" flexDirection="row" gap="12px">
            {isTypeInboundAndAnswered && (
              <IconButton
                color="primary"
                onClick={() => handleAudioClick(recordingId)}
              >
                {audioLoading ? (
                  <CircularProgress size={24} />
                ) : isPlaying ? (
                  <Pause />
                ) : (
                  <PlayArrow />
                )}
              </IconButton>
            )}
            {isPlaying && <ReactAudioPlayer src={audioSrc} autoPlay controls />}
          </Box>
        );
      },
    },
  ];

  // Opens a menu when a phone number is clicked
  const handleOpenMenu = (event, phoneNumber) => {
    setAnchorEl(event.currentTarget);
    setSelectedNumber(phoneNumber);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleCopyNumber = () => {
    navigator.clipboard.writeText(selectedNumber);
    handleCloseMenu();
  };

  const handleCallNumber = () => {
    handleCallClick(selectedNumber);
    handleCloseMenu();
  };

  // Handles the call event for a given phone number.
  // It triggers the external makeCall function and emits an event.
  const handleCallClick = (phoneNumber) => {
    if (typeof window.makeCall === "function") {
      window.makeCall(phoneNumber);
    } else {
      console.error("makeCall function not found");
    }

    // Emit the event to inform callclicked event
    eventEmitter.emit("callClicked");
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleCopyNumber}>Copy</MenuItem>
        <MenuItem onClick={handleCallNumber}>Call</MenuItem>
      </Menu>
      <Box width="100%" height="60vh">
        {!tableDataLoading && tableData && (
          <>
            <DataGrid
              columns={columns}
              rows={tableData?.data}
              loading={tableDataLoading}
              hideFooter="true"
              getRowId={(row) => row._id}
            />
            <CustomPagination
              currentPage={currentPage}
              totalPage={tableData?.totalPages}
              handleLeftClick={handleLeftClick}
              handleRightClick={handleRightClick}
              handleFirstPageClick={handleFirstPageClick}
              handleLastPageClick={handleLastPageClick}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default CallHistoryTable;
